import React from 'react';

const IconStackOverflow = function () {
  return (
    <svg
      xmlns = "http://www.w3.org/2000/svg"
      role = "img"
      viewBox = "0 0 15 15"
      fill = "none"
      stroke = "currentColor"
      strokeWidth = "1"
      className = "feather feather-stackoverflow"
    >
      <title>StackOverflow</title>
      <path d = "M2.5 9V14.5H12.5V9M4 12.5H11M4 10L11 10.7M4.29999 7.5L11 9M5.29999 4.5L11.4 7.6M7 2L12 6.50002M9.5 0.5L13 5.5" />
    </svg>
  );
};

export default IconStackOverflow;
