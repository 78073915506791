import React from 'react';

const IconLogo = function () {
  return (
    <svg id = "logo" xmlns = "http://www.w3.org/2000/svg" role = "img" viewBox = "0 0 84 96">
      <title>Logo</title>
      <g transform = "translate(-8.000000, -2.000000)">
        <g transform = "translate(11.000000, 5.000000)">
          <g id = "B" transform = "translate(30.000000, 28.000000)">
            <path
              d = "M 0 35 L 0 0 L 20.75 0 L 20.75 4.1 L 4.75 4.1 L 4.75 14.8 L 18.1 14.8 L 18.1 18.7 L 4.75 18.7 L 4.75 35 L 0 35 Z"
              fill = "currentColor"
            />
          </g>
          <polygon
            id = "Shape"
            stroke = "currentColor"
            strokeWidth = "5"
            strokeLinecap = "round"
            strokeLinejoin = "round"
            points = "39 0 0 22 0 67 39 90 78 68 78 23"
          />
        </g>
      </g>
    </svg>
  );
};

export default IconLogo;
