import React from 'react';

const IconLoader = function () {
  return (
    <svg id = "logo" xmlns = "http://www.w3.org/2000/svg" viewBox = "0 0 100 100">
      <title>Loader Logo</title>
      <g>
        <g id = "B" transform = "translate(40.000000, 32.000000)">
          <path
            d = "M 0 35 L 0 0 L 20.75 0 L 20.75 4.1 L 4.75 4.1 L 4.75 14.8 L 18.1 14.8 L 18.1 18.7 L 4.75 18.7 L 4.75 35 L 0 35 Z"
            fill = "currentColor"
          />
        </g>
        <path
          stroke = "currentColor"
          strokeWidth = "5"
          strokeLinecap = "round"
          strokeLinejoin = "round"
          d = "M 50, 5
                  L 11, 27
                  L 11, 72
                  L 50, 95
                  L 89, 73
                  L 89, 28 z"
        />
      </g>
    </svg>
  );
};

export default IconLoader;
